import React from "react";

export const SearchIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16 40L24.636 31.364M24.636 31.364C26.2647 32.9926 28.5147 34 31 34C35.9706 34 40 29.9706 40 25C40 20.0294 35.9706 16 31 16C26.0294 16 22 20.0294 22 25C22 27.4853 23.0074 29.7353 24.636 31.364ZM55 28C55 42.9117 42.9117 55 28 55C13.0883 55 1 42.9117 1 28C1 13.0883 13.0883 1 28 1C42.9117 1 55 13.0883 55 28Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ResetIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 34L34 2M2 2L34 34"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OpenIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.1811 42.8189C24.2385 42.8763 24.3066 42.9218 24.3816 42.9529C24.4566 42.984 24.537 43 24.6181 43C24.6993 43 24.7797 42.984 24.8546 42.9529C24.9296 42.9218 24.9977 42.8763 25.0551 42.8189L43.7647 24.1092V38.6761C43.7647 38.8399 43.8298 38.997 43.9456 39.1128C44.0615 39.2286 44.2186 39.2937 44.3824 39.2937C44.5462 39.2937 44.7033 39.2286 44.8191 39.1128C44.9349 38.997 45 38.8399 45 38.6761V22.6176C45 22.4538 44.9349 22.2967 44.8191 22.1809C44.7033 22.0651 44.5462 22 44.3824 22H28.3239C28.1601 22 28.003 22.0651 27.8872 22.1809C27.7714 22.2967 27.7063 22.4538 27.7063 22.6176C27.7063 22.7814 27.7714 22.9385 27.8872 23.0544C28.003 23.1702 28.1601 23.2353 28.3239 23.2353H42.8908L24.1811 41.9449C24.1237 42.0023 24.0782 42.0704 24.0471 42.1454C24.016 42.2203 24 42.3007 24 42.3819C24 42.463 24.016 42.5434 24.0471 42.6184C24.0782 42.6934 24.1237 42.7615 24.1811 42.8189Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export const LoadingIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
