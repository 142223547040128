import React from "react";

import "./App.css";
import SearchPage from "./components/SearchPage";

function App() {
  return (
    <div className="flex flex-col h-full" /*justify-between"*/>
      <SearchPage />
    </div>
  );
}

export default App;
