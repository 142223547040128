import React from "react";
import ResultDocument from "./ResultDocument";

type SearchResultProps = {
  documents: any[];
  output: string;
};

const SearchResult: React.FC<SearchResultProps> = ({ output, documents }) => {
  console.log("Output: ");
  console.log(output);
  console.log("Documents: ");
  console.log(documents);
  return (
    <div className="flex flex-col items-start px-14 pb-20 bg-davies-grey max-md:px-5">
      <div className="flex flex-col justify-center text-3xl tracking-wide leading-10 text-white max-md:max-w-full">
        <div className="flex flex-col justify-center max-md:max-w-full">
          <div className="flex flex-col pb-9 max-md:max-w-full">
            <div className="flex flex-col justify-center max-md:max-w-full">
              <p className="justify-center max-md:max-w-full font-neue-haas">{output}</p>
            </div>
          </div>
        </div>
      </div>
      {documents.length > 0 && (
      <div className="flex flex-col pb-20 mt-6 bg-davies-grey max-md:max-w-full">
        <h2 className="justify-center self-start text-sm font-bold tracking-wide leading-5 text-white uppercase whitespace-nowrap font-neue-haas-bold">
          Citations
        </h2>
        <div className="flex flex-col mt-11 mb-4 max-md:mt-10 max-md:max-w-full">
          {documents?.map((doc, index) => (
            <ResultDocument key={index} document={doc} />
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default SearchResult;
