import React from "react";
import { OpenIcon } from "./Icons";
import { truncateText } from "../utils/helpers";

const upperFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

type TabContentProps = {
  title: string;
  authors: string[];
  description: string;
};

const TabContent: React.FC<TabContentProps> = ({
  title,
  authors,
  description,
}) => (
  <div className="flex gap-5 py-6 border-t border-b border-white border-solid max-md:flex-wrap text-left">
    <div className="flex flex-col flex-1 px-5 max-md:max-w-full">
      <header className="flex flex-col justify-center leading-[150%] max-md:max-w-full">
        <div className="flex gap-5 pr-20 max-md:flex-wrap max-md:pr-5 font-neue-haas">
          <h2 className="text-2xl tracking-wide text-white">{title}</h2>
          {authors?.map((author, index) => (
            <span
              key={index}
              className="justify-center px-3 py-1 my-auto text-base tracking-wide text-right text-yellow-400 uppercase rounded-lg bg-black bg-opacity-20"
            >
              {author}
            </span>
          ))}
        </div>
      </header>
      <p className="mt-2 text-lg leading-8 text-white max-md:max-w-full font-georgia">
        {truncateText(description, 200)}
      </p>
    </div>
    <OpenIcon className="shrink-0 my-auto w-16 aspect-square text-white group-hover:bg-davies-bright-green group-hover:rounded-xl group-hover:text-black" />
  </div>
);

const ResultDocument: React.FC<any> = ({ document }: any) => {
  const documentName = document.metadata.title;
  let authors = document.metadata.authors;

  const total_authors = authors.length
  if(total_authors > 2){
    authors = authors.slice(0, 2);
    authors.push(`+${total_authors - 2}`);
  }

  const openLink = () => {
    const link = document.metadata.url;
    window.open(link, "_blank");
  };

  const tabContent: TabContentProps = {
    title: documentName,
    authors: authors,
    description: document.pageContent,
  };

  return (
    <button onClick={openLink} className="group">
      <TabContent {...tabContent} />
    </button>
  );
};

export default ResultDocument;
