import React, { useRef, useState } from "react";
import SearchComponent from "./SearchComponent";
import SearchResult from "./SearchResults";

const apiUrl =
  "https://2kishkv5oxrggwxuudewcm242y0ivzou.lambda-url.us-east-1.on.aws";

type LogoProps = {
  src: string;
  hasOutput: boolean;
};

const Logo: React.FC<LogoProps> = ({ src, hasOutput }) => (
  <div className={`flex justify-center pr-10 max-md:mt-10 ${hasOutput ? 'items-start pt-16 hidden md:flex' : 'items-end pb-20'}`}>
    <img
      loading="lazy"
      src={src}
      alt="Company logo"
      className="fill-white w-[131px]"
    />
  </div>
);

type MyComponentProps = {
  searchTitle: string;
  logoSrc: string;
};

const SearchPage: React.FC<MyComponentProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState<any>();

  const submit = async () => {
    setLoading(true);

    const text = inputRef.current?.value;

    if (!text) {
      setLoading(false);
      return;
    }

    try {
      const result = await fetch(`${apiUrl}?query=${text}`).then((res) =>
        res.json()
      );

      console.log(result);

      setOutput(result);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const reset = () => {
    console.log("Reset");
    // setOutput(undefined);
  };

  return (
    <div className={`overflow-auto top-0 bottom-0 w-full h-full bg-davies-grey flex gap-24 pb-20 flex-col md:flex-row`}>
      <div className={`flex flex-col flex-1 items-end max-md:flex-wrap ${output ? '' : 'justify-end'}`}>
        <SearchComponent
          headerTitle={"What are you looking for?"}
          handleOnClick={submit}
          className={"w-full"}
          inputRef={inputRef}
          resetCallback={reset}
          isLoading={loading}
        />
        {output && <SearchResult {...output} />}
      </div>
      <Logo hasOutput={!!output} src={"Davies_logo_svg_light_1.svg"} />
    </div>
  );
};

export default SearchPage;
