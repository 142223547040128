import React, { useState } from "react";
import { LoadingIcon, ResetIcon, SearchIcon } from "./Icons";

type SearchComponentProps = {
  headerTitle: string;
  className: string;
  isLoading: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleOnClick: React.FormEventHandler;
  resetCallback: () => void
};

const SearchComponent: React.FC<SearchComponentProps> = ({
  headerTitle,
  className,
  isLoading,
  inputRef,
  handleOnClick,
  resetCallback
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSearched && searchTerm !== event.target.value) {
      setIsSearched(false);
    }
    setSearchTerm(event.target.value);
  };

  const reset = () => {
    console.log("Reset");
    setSearchTerm("");
    resetCallback();
    setIsSearched(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchTerm) {
      setIsSearched(true);
      handleOnClick(e)
    }
  }

  return (
    <div
      className={`flex flex-col justify-between pl-14 pt-16 pb-20 text-4xl tracking-wider leading-10 text-white bg-davies-grey max-md:pl-5 ${className}`}
    >
      <div className="flex flex-col justify-center max-md:max-w-full">
        <form className="flex gap-0.5 items-start pb-2 border-yellow-400 border-solid border-b-[3px] font-neue-haas" onSubmit={onSubmit}>
          <input
            type="text"
            placeholder={headerTitle}
            value={searchTerm}
            onChange={handleSearchChange}
            className="flex-1 my-auto bg-transparent placeholder-current max-md:max-w-full focus:outline-none text-ellipsis max-sm:w-[90%]"
            ref={inputRef}
          />
          {!isLoading && !isSearched && <button >
            <SearchIcon className="shrink-0 self-start aspect-square w-[72px]" />
          </button>}
          {!isLoading && isSearched && <button onClick={reset}>
            <ResetIcon className="shrink-0 self-start aspect-square w-[72px] my-[10px] ml-20" />
          </button>}
          {isLoading && <span>
            <LoadingIcon className="animate-spin shrink-0 self-start aspect-square w-[52px] my-[10px] mx-[10px] ml-20 text-white" />
          </span>}
        </form>
      </div>
    </div>
  );
};

export default SearchComponent;
